import { Images } from "../common";
import EnjoyItem from "./EnjoyItem";

export default function Enjoy() {
  return (
    <div className="sol-enjoy">
      <div className="container">
        <h1>Enjoy Your Journey with SOLDIER RABBITS</h1>
        <EnjoyItem
          content={() => (
            <div>
              All SOLDIER RABBITS are born on Solana
              <br /> blockchain with proof of ownership.
            </div>
          )}
          image={Images.RabbitRed}
        />
        <EnjoyItem
          content={() => (
            <div>
              With over 100 unique attributes, <br />
              including furs, hat, outfits, and
              <br />
              backgrounds, your rabbits are <br /> 100% unique from others.
            </div>
          )}
          image={Images.Attributes}
        />
        <EnjoyItem
          content={() => (
            <div>
              Some rabbits are more rare
              <br /> depending on the rarity of their <br />
              attributes.
            </div>
          )}
          image={Images.RabbitYellow}
        />
        <EnjoyItem
          content={() => (
            <div>
              The commercial rights belong to
              <br /> the owner of the SOLDIER RABBITS.
            </div>
          )}
          image={Images.RabbitBlue}
        />
      </div>
    </div>
  );
}
