import Logo from "../assets/images/common/logo.svg";
import Home1 from "../assets/images/home/home-1.png";
import Section from "../assets/images/common/section.png";
import Gun1 from "../assets/images/home/rabbit-gun-1.png";
import Gun2 from "../assets/images/home/rabbit-gun-2.png";
import Gun3 from "../assets/images/home/rabbit-gun-3.png";
import Radish from "../assets/images/common/radish.png";
import Radish1 from "../assets/images/common/radish-1.png";
import Radish1Svg from "../assets/images/common/radish-1.svg";
import Radish3 from "../assets/images/common/radish-3.png";
import Radish3Svg from "../assets/images/common/radish-3.svg";
import Radish5 from "../assets/images/common/radish-5.png";
import Radish5Svg from "../assets/images/common/radish-5.svg";
import RadishButton1 from "../assets/images/common/radish-button-1.png";
import RadishButton3 from "../assets/images/common/radish-button-3.png";
import RadishButton5 from "../assets/images/common/radish-button-5.png";
import RabbitYellow from "../assets/images/home/rabbit-yellow.png";
import RabbitBlue from "../assets/images/home/rabbit-blue.png";
import RabbitRed from "../assets/images/home/rabbit-red.png";
import Attributes from "../assets/images/home/attributes.png";
import Discord from "../assets/images/common/discord.svg";
import Twitter from "../assets/images/common/twitter.svg";
import RabbitAttributeWhite from "../assets/images/attributes/rabbit-white.png";
import RabbitAttributeYellow from "../assets/images/attributes/rabbit-yellow.png";
import RabbitAttributeBlack from "../assets/images/attributes/rabbit-black.png";
import RabbitAttributeBone from "../assets/images/attributes/rabbit-bone.png";
import Roadmap1 from "../assets/images/roadmap/roadmap-1.svg";
import Roadmap2 from "../assets/images/roadmap/roadmap-2.svg";
import Roadmap3 from "../assets/images/roadmap/roadmap-3.svg";
import Roadmap4 from "../assets/images/roadmap/roadmap-4.svg";
import ConnectWallet from "../assets/images/common/connect-wallet.png";
import Mint from "../assets/images/home/mint.png";
import Mintsuccess from "../assets/images/common/mint-success.png";
import Mintwarning from "../assets/images/common/mint-warning.png";
import Minterror from "../assets/images/common/mint-error.png";
import Wallet from "../assets/images/common/wallet.png";
import Common from "../assets/images/rarity/common.png";
import Rare from "../assets/images/rarity/rare.png";
import SpecialRare from "../assets/images/rarity/special-rare.png";
import SpecialSuper from "../assets/images/rarity/special-super.png";
import UltraRare from "../assets/images/rarity/ultra-rare.png";
import LegendRare from "../assets/images/rarity/legend-rare.png";
import Menu from "../assets/images/common/menu.png";
import MenuHome from "../assets/images/common/menu-home.png";

export default {
  Logo,
  Home1,
  Section,
  Gun1,
  Gun2,
  Gun3,
  Radish,
  Radish1,
  Radish3,
  Radish5,
  RadishButton1,
  RadishButton3,
  RadishButton5,
  RabbitYellow,
  RabbitBlue,
  RabbitRed,
  Discord,
  Twitter,
  Attributes,
  RabbitAttributeWhite,
  RabbitAttributeYellow,
  RabbitAttributeBlack,
  RabbitAttributeBone,
  Roadmap1,
  Roadmap2,
  Roadmap3,
  Roadmap4,
  ConnectWallet,
  Mint,
  Mintsuccess,
  Mintwarning,
  Minterror,
  Wallet,
  Common,
  Rare,
  SpecialRare,
  SpecialSuper,
  UltraRare,
  LegendRare,
  Menu,
  MenuHome,
  Radish1Svg,
  Radish3Svg,
  Radish5Svg,
};
