import { useState, useEffect } from "react";

export default function useCountDown() {
  const [state, setState] = useState<any>(null);
  const [sellDate, setSellDate] = useState<string>("");

  useEffect(() => {
    if (sellDate) {
      const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

      let x = setInterval(function () {
        let startAt = new Date(sellDate),
          cd = new Date(startAt).getTime();
        let d = new Date();
        let now = new Date(
            d.getUTCFullYear(),
            d.getUTCMonth(),
            d.getUTCDate(),
            d.getUTCHours(),
            d.getUTCMinutes(),
            d.getUTCSeconds()
          ).getTime(),
          distance = cd - now;

        let days = Math.floor(distance / day),
          hours = Math.floor((distance % day) / hour),
          minutes = Math.floor((distance % hour) / minute),
          seconds = Math.floor((distance % minute) / second);

        setState({
          ...state,
          countDown: {
            days,
            hours,
            minutes,
            seconds,
          },
        });

        if (distance < 0) {
          clearInterval(x);
          setState({ ...state, countDown: null });
        }
        //seconds
      }, 0);
    }
  }, [sellDate]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_API}/api/get-infomation`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.sell_date) {
          const d = new Date(res.sell_date),
            dateStr =
              d.getFullYear() +
              "-" +
              ("00" + (d.getMonth() + 1)).slice(-2) +
              "-" +
              ("00" + d.getDate()).slice(-2) +
              "T" +
              ("00" + d.getHours()).slice(-2) +
              ":" +
              ("00" + d.getMinutes()).slice(-2) +
              ":" +
              ("00" + d.getSeconds()).slice(-2);

          setState({
            sold_out: res.sold_out === "true",
          });
          setSellDate(dateStr);
        }
      });
  }, []);

  return state;
}
