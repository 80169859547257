import CountDown from "./Countdown";

export default function MintRabbit({ onMint, isMinting, countDown }: any) {
  return (
    <div className="sol-mint-rabbit">
      <div className="container">
        <h1>Mint SOLDIER RABBITS</h1>
        <h4>Mint SOLDIER RABBITS by connecting your wallet.</h4>
        <CountDown countDown={countDown} />
        <div className="sol-row">
          <div className="sol-mint-1">
            <div className="sol-mint-item">
              <h3>Single</h3>
              <h4>1 SOLDIER RABBIT NFT:</h4>
              <div className="sol-price">2 SOL</div>
              <div className="sol-divide" />
              <div className="sol-mint-icons">
                <RabishBlue />
              </div>
              <div className="sol-rabbit-waiting">
                Rabbits Are Waiting for You
              </div>
              <div className="sol-button" onClick={() => onMint(1)}>
                <div className="sol-mint-btn-text">
                  {" "}
                  {isMinting == 1 ? (
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    <>Mint &nbsp;1 SOLDIER RABBIT</>
                  )}
                </div>
              </div>
            </div>
          </div>
          {process.env.REACT_APP_CONTAINER_TESTING == "main-test" && (
            <div className="sol-mint-3">
              <div className="sol-mint-item">
                <h3>Triple</h3>
                <h4>3 SOLDIER RABBITS NFT:</h4>
                <div className="sol-price">6 SOL</div>
                <div className="sol-divide" />
                <div className="sol-row">
                  <RabishPurple />
                  <RabishPurple />
                  <RabishPurple />
                </div>

                <div className="sol-rabbit-waiting">
                  Rabbits are waiting for you
                </div>
                <div className="sol-button" onClick={() => onMint(3)}>
                  <div className="sol-mint-btn-text">
                    {" "}
                    {isMinting == 3 ? (
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      <>Mint &nbsp;3 SOLDIER RABBITS</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {process.env.REACT_APP_CONTAINER_TESTING == "main-test" && (
          <div className="sol-mint-5">
            <div className="sol-mint-item">
              <h3>Quintuple</h3>
              <h4>5 SOLDIER RABBITS NFT:</h4>
              <div className="sol-price">10 SOL</div>
              <div className="sol-divide" />
              <div className="sol-row">
                <RabishYellow />
                <RabishYellow />
                <RabishYellow />
                <RabishYellow />
                <RabishYellow />
              </div>

              <div className="sol-rabbit-waiting">
                Rabbits are waiting for you
              </div>
              <div className="sol-button" onClick={() => onMint(5)}>
                <div className="sol-mint-btn-text">
                  {" "}
                  {isMinting == 5 ? (
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    <>Mint &nbsp;5 SOLDIER RABBITS</>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function RabishBlue() {
  return (
    <svg width={59.98} height={87.609} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter
          id="prefix__blue"
          x={0}
          y={0}
          width={119.98}
          height={122.609}
          filterUnits="userSpaceOnUse"
        >
          <feOffset />
          <feGaussianBlur stdDeviation={8} result="blur" />
          <feFlood floodColor="#0df" />
          <feComposite operator="in" in2="blur" result="result1" />
          <feComposite in="SourceGraphic" in2="result1" />
        </filter>
      </defs>
      <g filter="url(#prefix__blue)" transform="translate(-30 -20)">
        <path
          data-name="Icon awesome-carrot"
          d="M49.566 52.533a13.989 13.989 0 00-2.822 17.082l8.075-3.478a1.802 1.802 0 111.424 3.306L48.9 72.606a52.177 52.177 0 008.393 12.586c5.539 6.082 6.316 6.902 10.467 9.903 4.151 3.001 5.043 2.572 6.14 2.1a3.603 3.603 0 001.543-2.14 75.168 75.168 0 001.457-9.109 83.318 83.318 0 00.213-9.512L66.986 81.46a1.788 1.788 0 01-2.365-.941 1.808 1.808 0 01.941-2.365l11.268-5.518a98.832 98.832 0 00-1.033-11.441c-.903-5.767-3.507-9.868-7.754-11.764-4.247-1.897-12.76-2.103-18.476 3.098zm3.814-10.896c-3.447-3.735-8.902-4.682-13.995-1.896 2.27 6.999 9.062 9.968 15.564 7.15l1.17-.504c6.517-2.794 9.028-9.777 5.497-16.223-5.523 1.788-8.58 6.403-8.237 11.472z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

function RabishPurple() {
  return (
    <svg width={59.98} height={87.609} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter
          id="prefix__purple"
          x={0}
          y={0}
          width={119.98}
          height={122.609}
          filterUnits="userSpaceOnUse"
        >
          <feOffset />
          <feGaussianBlur stdDeviation={8} result="blur" />
          <feFlood floodColor="#c305ff" />
          <feComposite operator="in" in2="blur" result="result1" />
          <feComposite in="SourceGraphic" in2="result1" />
        </filter>
      </defs>
      <g filter="url(#prefix__purple)" transform="translate(-30 -20)">
        <path
          data-name="Icon awesome-carrot"
          d="M49.566 52.533a13.989 13.989 0 00-2.822 17.082l8.075-3.478a1.802 1.802 0 111.424 3.306L48.9 72.606a52.177 52.177 0 008.393 12.586c5.539 6.082 6.316 6.902 10.467 9.903 4.151 3.001 5.043 2.572 6.14 2.1a3.603 3.603 0 001.543-2.14 75.168 75.168 0 001.457-9.109 83.318 83.318 0 00.213-9.512L66.986 81.46a1.788 1.788 0 01-2.365-.941 1.808 1.808 0 01.941-2.365l11.268-5.518a98.832 98.832 0 00-1.033-11.441c-.903-5.767-3.507-9.868-7.754-11.764-4.247-1.897-12.76-2.103-18.476 3.098zm3.814-10.896c-3.447-3.735-8.902-4.682-13.995-1.896 2.27 6.999 9.062 9.968 15.564 7.15l1.17-.504c6.517-2.794 9.028-9.777 5.497-16.223-5.523 1.788-8.58 6.403-8.237 11.472z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

function RabishYellow() {
  return (
    <svg width={59.98} height={87.609} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter
          id="prefix__yellow"
          x={0}
          y={0}
          width={119.98}
          height={122.609}
          filterUnits="userSpaceOnUse"
        >
          <feOffset />
          <feGaussianBlur stdDeviation={8} result="blur" />
          <feFlood floodColor="#ffee05" />
          <feComposite operator="in" in2="blur" result="result1" />
          <feComposite in="SourceGraphic" in2="result1" />
        </filter>
      </defs>
      <g filter="url(#prefix__yellow)" transform="translate(-30 -20)">
        <path
          data-name="Icon awesome-carrot"
          d="M49.566 52.533a13.989 13.989 0 00-2.822 17.082l8.075-3.478a1.802 1.802 0 111.424 3.306L48.9 72.606a52.177 52.177 0 008.393 12.586c5.539 6.082 6.316 6.902 10.467 9.903 4.151 3.001 5.043 2.572 6.14 2.1a3.603 3.603 0 001.543-2.14 75.168 75.168 0 001.457-9.109 83.318 83.318 0 00.213-9.512L66.986 81.46a1.788 1.788 0 01-2.365-.941 1.808 1.808 0 01.941-2.365l11.268-5.518a98.832 98.832 0 00-1.033-11.441c-.903-5.767-3.507-9.868-7.754-11.764-4.247-1.897-12.76-2.103-18.476 3.098zm3.814-10.896c-3.447-3.735-8.902-4.682-13.995-1.896 2.27 6.999 9.062 9.968 15.564 7.15l1.17-.504c6.517-2.794 9.028-9.777 5.497-16.223-5.523 1.788-8.58 6.403-8.237 11.472z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
