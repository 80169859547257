import { Helmet } from "react-helmet";
import { EnjoyItem } from "../components";
import { Images } from "../common";

export default function RoadMap() {
  return (
    <div className="sol-roadmap">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roadmap</title>
        <meta name="description" content="Solider Rabbits" />
      </Helmet>
      <div className="container">
        <h1>Roadmap</h1>
        <EnjoyItem
          content={() => (
            <div>
              All SOLDIER RABBITS are born on Solana <br />
              blockchain with proof of ownership.
            </div>
          )}
          type="svg"
          image={Images.Roadmap1}
        />
        <EnjoyItem
          content={() => (
            <div>
              <div className="sol-title">Release the SOLDIER RABBITS:</div>
              <div>
                First time on September 10th the SOLDIER RABBITS land on Solana.
                <br /> The remaining 1,500 NFTs land on September 17th 4PM UTC
                <br />
                The 3rd launch 1,000 NFTs each land on September 18th 1PM UTC
                and 18th 11PM UTC.
              </div>
            </div>
          )}
          type="svg"
          image={Images.Roadmap2}
        />
        <EnjoyItem
          content={() => (
            <div>
              <div className="sol-title">
                Opening of the secondary
                <br /> market in Solana:
              </div>
              <div>
                At the end of the mint, buy, sell and trade SOLDIER RABBITS on
                Digital Eyes Market place.
              </div>
            </div>
          )}
          type="svg"
          image={Images.Roadmap3}
        />
        <EnjoyItem
          content={() => (
            <div>
              <div className="sol-title">Let's decide what to do next:</div>
              <div>
                What do you want to do next?
                <br />
                We'll keep marketing and working hard while you keep selling on
                secondary market.
              </div>
            </div>
          )}
          type="svg"
          image={Images.Roadmap4}
        />
      </div>
    </div>
  );
}
