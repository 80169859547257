import { Helmet } from "react-helmet";
import { Section } from "../components";

export default function Faq() {
  return (
    <div className="sol-faq">
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAQ</title>
        <meta name="description" content="Solider Rabbits" />
      </Helmet>
      <div className="container">
        <h1>FAQ</h1>
        <Section>
          <h2>What are SOLDIER RABBITS?</h2>
          <h3>
            10,000NFTs generated for our new project on Solana Blockchain.
            <br />
            Unique & Cute SOLDIER RABBITS for great collectors.
          </h3>
        </Section>
        <Section className="sol-long-content">
          <h2>When is the mint/selling date?</h2>
          <h3>
            We have already sold 555 NFTs out of the 10,000 NTFs in the 1st
            launch.
            <br />
            For the 2nd launch, 1,500 NFTs can be minted at 4PM UTC on September
            17.
            <br />
            The 3rd launch will take place some time in September.
            <br />
            It will be posted on DigitalEyes before the 3rd launch and will be
            available for trading.
          </h3>
        </Section>
        <Section>
          <h2>How much are SOLDIER RABBITS?</h2>
          <h3>2 SOL per Rabbit.</h3>
        </Section>
        <Section>
          <h2>How to buy SOLDIER RABBITS?</h2>
          <h3>
            SOLDIER RABBITS website will mint a random Degenerate Rabbit or find
            one available for resale in the secondary market.
          </h3>
        </Section>
        <Section>
          <h2>How many SOLDIER RABBITS will there be?</h2>
          <h3>There will be 10,000 unique SOLDIER RABBITS.</h3>
        </Section>
        <Section>
          <h2>Where can I see the rabbits I’ve purchased?</h2>
          <h3>
            You can view them directly on the Phantom wallet Collectible tab or
            head over to the My rabbits section after connecting the wallet that
            has the SOLDIER RABBITS token.
          </h3>
        </Section>
        <Section>
          <h2>Will there be resale royalties?</h2>
          <h3>3.15% royalties applied to all resales.</h3>
        </Section>
        <Section>
          <h2>Where can I find your roadmap?</h2>
          <h3>
            <a href="https://soldierrabbits.io/roadmap" target="_blank">
              {" "}
              https://soldierrabbits.io/roadmap
            </a>
          </h3>
        </Section>
        <Section>
          <h2>What blockchain does SOLDIER RABBITS?</h2>
          <h3>The Solana Blockchain.</h3>
        </Section>
        <Section>
          <h2>Will there be an aftermarket?</h2>
          <h3>On DigitalEyes</h3>
        </Section>
      </div>
    </div>
  );
}
