import { Images } from "../common";
import Section from "./Section";
import CountDown from "./Countdown";

export default function Introduce({ onMint, isMinting, countDown }: any) {
  return (
    <div className="container">
      <div className="sol-introduce">
        <h1>Catch SOLDIER RABBITS!</h1>

        <div className="sol-buy" onClick={() => onMint(-1)}>
          <img src={Images.Mint} />
          {isMinting == -1 ? (
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : countDown ? (
            <span className="btn-buy-sol">Mint SOLDIER RABBITS Soon!</span>
          ) : (
            <span className="btn-buy-sol">Mint SOLDIER RABBITS Now!</span>
          )}
        </div>
        <CountDown countDown={countDown} />
        <img className="sol-rabbit-blue" src={Images.Home1} />
        <Section>
          <h2>What are SOLDIER RABBITS?</h2>
          <h3>
            10,000NFTs generated for our new project on Solana Blockchain.
            <br />
            Unique & Cute SOLDIER RABBITS for great collectors.
          </h3>
        </Section>
        <div className="sol-rabbit-gun">
          <img src={Images.Gun1} />
          <img src={Images.Gun2} />
          <img src={Images.Gun3} />
        </div>
      </div>
    </div>
  );
}
