import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import { Button } from "@material-ui/core";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useWallet } from "@solana/wallet-adapter-react";
import {
  WalletDialogButton,
  useWalletDialog,
} from "@solana/wallet-adapter-material-ui";
import { Introduce, MintRabbit, Enjoy } from "../components";
import { Helmet } from "react-helmet";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "../candy-machine";
import { Images } from "../common";
import { useCountDown } from "../hooks";

const CounterText = styled.span``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const { setOpen } = useWalletDialog();
  const [balance, setBalance] = useState<number>();
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState<any>(); // true when user got to press MINT
  const [sellDate, setSellDate] = useState("");

  const countDown = useCountDown();
  const ref = useRef<any>();
  useOnClickOutside(ref, () => setAlertState({ ...alertState, open: false }));

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useWallet();
  //const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const onMint = async (quantity: number) => {
    if (!countDown) {
      return;
    }
    // check countdown
    if (countDown.countDown) {
      setAlertState({
        open: true,
        message: "Coming soon",
        severity: "success",
      });
      return;
    }
    // check sold out
    const result = await getMetadata();

    if (result.sold_out === "true") {
      setAlertState({
        open: true,
        message: "SOLD OUT",
        severity: "warning",
      });
      return;
    }

    if (!wallet.publicKey) {
      setOpen(true);
      return;
    }
    if (!isNaN(isMinting)) {
      return;
    }
    try {
      setIsMinting(quantity);

      if (wallet.connected && wallet.publicKey) {
        let message = {
          error: 0,
          success: 0,
        };
        const loop = quantity < 0 ? 1 : quantity;
        for (let i = 0; i < loop; ++i) {
          const res = await mintToken();
          if (!res) {
            message.error += 1;
          } else {
            message.success += 1;
          }
        }

        if (message.success == loop) {
          setAlertState({
            open: true,
            message:
              "Congratulations！Minting success、please check your Collections.",
            severity: "success",
          });
        } else if (message.error == loop) {
          setAlertState({
            open: true,
            message:
              "Fail! Sorry about that. Please check your Connection of Wallet and Mint it again.",
            severity: "error",
          });
        } else {
          setAlertState({
            open: true,
            message: `Minting success ${message.success}, fail ${message.error}`,
            severity: "warning",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = "Transaction time out. Please try again";
      let severity: any = "error";
      //const { isLast } = await getConfig(props.candyMachineId, 1);

      // if (error.code === 311 && !isLast) {
      //   message = `NFTs Sold out! `;
      //   severity = "warning";
      // }

      // if (!error.msg) {
      //   if (error.message.indexOf("0x138")) {
      //   } else if (error.message.indexOf("0x137")) {
      //     message = `NFTs Sold out! `;
      //     severity = "warning";
      //   } else if (error.message.indexOf("0x135")) {
      //     message = `Insufficient funds to mint. Please fund your wallet.`;
      //   }
      // } else {
      //   if (error.code === 311) {
      //     message = `NFTs Sold out! `;
      //     severity = "warning";
      //     setIsSoldOut(true);
      //   } else if (error.code === 312) {
      //     message = `Minting period hasn't started yet.`;
      //   }
      // }

      setAlertState({
        open: true,
        message,
        severity,
      });
    } finally {
      if (wallet?.publicKey) {
        const balance = await props.connection.getBalance(wallet?.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(undefined);
    }
  };

  const getMetadata = async () => {
    const result = await fetch(
      `${process.env.REACT_APP_BASE_API}/api/get-infomation`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((response) => response.json());

    return result;
  };

  const mintToken = async () => {
    const { config, candyMachine } = await getConfig(props.candyMachineId, 1);

    if (wallet.connected && candyMachine?.program && wallet.publicKey) {
      const mintTxId = await mintOneToken(
        candyMachine,
        config,
        wallet.publicKey,
        props.treasury
      );

      const status = await awaitTransactionSignatureConfirmation(
        mintTxId,
        props.txTimeout,
        props.connection,
        "singleGossip",
        false
      );
      if (!status?.err) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    (async () => {
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !wallet.signTransaction
      ) {
        return;
      }

      const anchorWallet = {
        publicKey: wallet.publicKey,
        signAllTransactions: wallet.signAllTransactions,
        signTransaction: wallet.signTransaction,
      } as anchor.Wallet;

      const { candyMachine, goLiveDate, itemsRemaining } =
        await getCandyMachineState(
          anchorWallet,
          props.candyMachineId,
          props.connection
        );

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      //setCandyMachine(candyMachine);
    })();
  }, [wallet, props.candyMachineId, props.connection]);

  const getConfig = async (
    cdMachineId: anchor.web3.PublicKey,
    config: number
  ): Promise<any> => {
    const anchorWallet = {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;

    const { candyMachine, itemsRemaining } = await getCandyMachineState(
      anchorWallet,
      cdMachineId || props.candyMachineId,
      props.connection
    );
    let localConfig = !config ? 1 : config;

    if (
      itemsRemaining === 0 &&
      process.env["REACT_APP_CANDY_MACHINE_CONFIG" + (localConfig + 1)]
    ) {
      return await getConfig(
        new anchor.web3.PublicKey(
          process.env["REACT_APP_CANDY_MACHINE_ID" + (localConfig + 1)]!
        ),
        localConfig + 1
      );
    }

    return {
      config: new anchor.web3.PublicKey(
        process.env["REACT_APP_CANDY_MACHINE_CONFIG" + localConfig]!
      ),
      candyMachine,
      isLast: process.env["REACT_APP_CANDY_MACHINE_CONFIG" + (localConfig + 1)],
    };
  };

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Solider Rabbits</title>
        <meta name="description" content="Soldier Rabbits" />
      </Helmet>

      <Introduce
        onMint={onMint}
        isMinting={isMinting}
        countDown={countDown?.countDown}
      />
      <MintRabbit
        onMint={onMint}
        isMinting={isMinting}
        countDown={countDown?.countDown}
      />

      <Enjoy />
      {alertState.open ? (
        <div className="sol-dialog">
          <div ref={ref} className={"alert " + alertState.severity}>
            <img
              src={
                alertState.severity == "success"
                  ? Images.Mintsuccess
                  : alertState.severity == "warning"
                  ? Images.Mintwarning
                  : Images.Minterror
              }
            />
            {alertState.message}
          </div>
        </div>
      ) : null}
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

function useOnClickOutside(ref: any, handler: any) {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export default Home;
