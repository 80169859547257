import SVG from "react-inlinesvg";
export default function EnjoyItem({ content, image, type }: any) {
  return (
    <div className="sol-enjoy-item">
      <div className="sol-enjoy-content">
        <div className="sol-enjoy-border">{content()}</div>
      </div>
      {type == "svg" ? <SVG src={image} /> : <img src={image} />}
    </div>
  );
}
