import { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MintTools from "../components/MintTools";

const tokenName = "privateToken";

export default function Setting() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [state, setState] = useState({
    sold_out: false,
    sell_date: "",
  });

  const [isLogin, setIsLogin] = useState(false);

  const getToken = () => localStorage.getItem(tokenName);
  const setToken = (token: any) => localStorage.setItem(tokenName, token);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_API}/api/auth/is-login`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          setIsLogin(true);
        }
      })
      .catch((e) => {
        setIsLogin(false);
      });
  }, []);

  useEffect(() => {
    if (isLogin) {
      fetch(`${process.env.REACT_APP_BASE_API}/api/get-infomation`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.sell_date) {
            const d = new Date(res.sell_date),
              dateStr =
                d.getFullYear() +
                "-" +
                ("00" + (d.getMonth() + 1)).slice(-2) +
                "-" +
                ("00" + d.getDate()).slice(-2) +
                "T" +
                ("00" + d.getHours()).slice(-2) +
                ":" +
                ("00" + d.getMinutes()).slice(-2) +
                ":" +
                ("00" + d.getSeconds()).slice(-2);

            setState({
              sold_out: res.sold_out === "true",
              sell_date: dateStr,
            });
          }
        });
    }
  }, [isLogin]);

  function onLogin(e: any) {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_BASE_API}/api/auth/login`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.token) {
          setToken(res.token);
          setIsLogin(true);
        } else {
          alert("Login fail. Please try again.");
        }
      })
      .catch((error) => {
        alert("Login fail. Please try again.");
      });
  }

  function onUpdate(e: any) {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_BASE_API}/api/update-metadata`, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      body: JSON.stringify({ metadata: state }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          alert("Update successful.");
        } else {
          alert("Update fail. Please try again.");
          setToken("");
          setIsLogin(false);
        }
      });
  }

  if (!isLogin) {
    return (
      <div className="sol-setting container">
        <h3>Login</h3>
        <form onSubmit={onLogin}>
          <div className="sol-col">
            <label htmlFor="uname">
              <b>Username</b>
            </label>
            <input
              type="text"
              placeholder="Enter Username"
              name="uname"
              required
              onChange={(e) => setUsername(e.target.value)}
            />

            <label htmlFor="psw">
              <b>Password</b>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="psw"
              required
              onChange={(e) => setPassword(e.target.value)}
            />

            <button type="submit">Login</button>
          </div>
        </form>
      </div>
    );
  }
  return (
    <div className="sol-setting container">
      <h3>Update Information</h3>
      <form onSubmit={onUpdate}>
        <div className="sol-col">
          <div className="sol-row">
            <label htmlFor="sold_out">
              <b>Sold Out</b>
            </label>
            <input
              type="checkbox"
              name="sold_out"
              checked={state.sold_out}
              onChange={(e) =>
                setState({ ...state, sold_out: !state.sold_out })
              }
            />
          </div>
          <div className="sol-row">
            <label htmlFor="sellDate">
              <b>Sell Date(UTC time)</b>
            </label>
            <input
              type="datetime-local"
              placeholder="Enter Sell Date"
              name="sell_date"
              required
              value={state.sell_date}
              onChange={(e) =>
                setState({ ...state, sell_date: e.target.value })
              }
            />
          </div>
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  );
}
