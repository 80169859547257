import { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import { Images } from "../common";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import SVG from "react-inlinesvg";
import { shortenAddress } from "../candy-machine";

export default function MainLayout({ children, connection }: any) {
  const wallet = useWallet();
  const [balance, setBalance] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  let location = useLocation();

  useEffect(() => {
    (async () => {
      if (wallet?.publicKey) {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, connection]);

  useEffect(() => {
    setOpenMenu(false);
  }, [location]);

  const onToggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div className={`main ${process.env.REACT_APP_CONTAINER_TESTING}`}>
      <div className="container">
        <div className="sol-header">
          <div className="sol-logo">
            <Link to="/">
              <SVG src={Images.Logo} />
            </Link>
          </div>
          <NavMenu handleClick={onToggleMenu} openMenu={openMenu} />
          <ul className={`sol-main-menu ${openMenu ? "open" : ""}`}>
            <li className="sol-home">
              <Link to="/">Home</Link>
              <div className="corner" />
            </li>
            <li>
              <Link to="/rarity">Rarity</Link>
            </li>
            <li>
              <Link to="/roadmap">Roadmap</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
          </ul>
          {!wallet.connected ? (
            <WalletDialogButton className="sol-connect-wallet">
              Connect Wallet
            </WalletDialogButton>
          ) : (
            <div className="sol-connected">
              <div className="sol-connect-wallet">
                {shortenAddress(wallet.publicKey?.toBase58() || "")}
              </div>
              <div className="sol-balance">
                <img src={Images.Wallet} />
                {(balance || 0).toLocaleString()} SOL
              </div>
            </div>
          )}
        </div>
      </div>
      {children}
      <div className="footer">
        <div className="footer-follow">
          <div className="sol-follow">
            Follow us and <br /> join the SOLDIER RABBITS community.
          </div>
          <div className="footer-icons">
            <a href="https://twitter.com/SoldierRabbits" target="_blank">
              <SVG src={Images.Twitter} />
            </a>
            <a href="https://discord.gg/fkHtxAvn" target="_blank">
              <SVG src={Images.Discord} />
            </a>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="sol-row">
              <div className="copyright-left">
                © Copyright 2021 SOLDIER RABBITS.
              </div>
              <div className="copyright-right">
                <span>Join Us</span>
                <div className="footer-icons">
                  <a href="https://twitter.com/SoldierRabbits" target="_blank">
                    <SVG src={Images.Twitter} />
                  </a>
                  <a href="https://discord.gg/fkHtxAvn" target="_blank">
                    <SVG src={Images.Discord} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function NavMenu({ handleClick, openMenu }: any) {
  return (
    <div
      className={`sol-nav-menu ${openMenu ? "open" : ""}`}
      onClick={handleClick}
    >
      <div className="sol-line sol-line-top" />
      <div className="sol-line sol-line-middle" />
      <div className="sol-line sol-line-bottom" />
    </div>
  );
}
