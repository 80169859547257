import { Helmet } from "react-helmet";
import { Images } from "../common";
export default function Rarity() {
  return (
    <div className="sol-attributes">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rarity</title>
        <meta name="description" content="Solider Rabbits" />
      </Helmet>
      <div className="container">
        <h1>Rarity</h1>
        <h4>
          With over 100 unique attributes, your rabbits are 100% unique from
          others.
        </h4>
        <div className="sol-rarity-item">
          <h1>Common 75%(7500)</h1>
          <h4>All normal parts</h4>
          <img src={Images.Common} />
        </div>
        <div className="sol-rarity-item">
          <h1>Rare 15%(1,500)</h1>
          <h4>Normal parts + Silver Background</h4>
          <img src={Images.Rare} />
        </div>
        <div className="sol-rarity-item">
          <h1>Special Rare 5%(500)</h1>
          <h4>Normal parts + Original background</h4>
          <h4>Rainbow parts + Silver background</h4>
          <img src={Images.SpecialRare} />
        </div>
        <div className="sol-rarity-item">
          <h1>Special Super Rare(350)</h1>
          <h4>1 Rainbow part + Golden Background</h4>
          <img src={Images.SpecialSuper} />
        </div>
        <div className="sol-rarity-item">
          <h1>Ultra Rare(130)</h1>
          <h4>1 Rainbow part + Rainbow Background</h4>
          <img src={Images.UltraRare} />
        </div>
        <div className="sol-rarity-item">
          <h1>Legend Rare(20)</h1>
          <h4>All Golden parts</h4>
          <h4>All Rainbow parts</h4>
          <img src={Images.LegendRare} />
        </div>
      </div>
    </div>
  );
}
