import { useCountDown } from "../hooks";

export default function CountDown({ countDown }: any) {
  if (!countDown) {
    return null;
  }

  return (
    <div className="sol-countdown">
      <div className="sol-countdown-col">
        <div className="sol-time">{countDown.days}</div>
        <div>Days</div>
      </div>
      <div className="sol-countdown-col">
        <div className="sol-time">{countDown.hours}</div>
        <div>Hours</div>
      </div>
      <div className="sol-countdown-col">
        <div className="sol-time">{countDown.minutes}</div>
        <div>Minutes</div>
      </div>
      <div className="sol-countdown-col">
        <div className="sol-time">{countDown.seconds}</div>
        <div>Second</div>
      </div>
    </div>
  );
}
